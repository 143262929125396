import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

export default ({ data }) => {
  const designer = data.allContentfulDesigner.edges[0].node
  const { firstName, lastName } = designer

  return (
    <Layout>
      <div>
        <h1>{`${firstName} ${lastName}`}</h1>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    allContentfulDesigner(filter: { contentful_id: { eq: $id } }) {
      edges {
        node {
          contentful_id
          firstName
          lastName
        }
      }
    }
  }
`
